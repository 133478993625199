"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainRenew = void 0;
// import { LastRenewAttemptManager } from "./last-renew-attempt-manager";
// const lastRenewedAttemptManager = new LastRenewAttemptManager();
const utils_1 = require("./utils");
const idButtonActualizarCodigos = 'update_all_codes_ranking';
const textSuccess = 'Se ha actualizado la clasificación de sus códigos';
function getRandBetween(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function getBtnThatContainsText(text) {
    var _a, _b;
    const elements = document.querySelectorAll(`body :not(script):not(style):not(template):not(noscript)`);
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        // Look for the element with the specified text directly under the document body
        if ((_a = element === null || element === void 0 ? void 0 : element.textContent) === null || _a === void 0 ? void 0 : _a.includes(text)) {
            return element;
        }
    }
    // If not found, look for the element with the specified text under any shallow root element
    const shallowRoots = document.querySelectorAll("body > *");
    for (let i = 0; i < shallowRoots.length; i++) {
        const childElement = shallowRoots[i].querySelector(":not(script):not(style):not(template):not(noscript)");
        if (!childElement) {
            continue;
        }
        if (childElement && ((_b = childElement === null || childElement === void 0 ? void 0 : childElement.textContent) === null || _b === void 0 ? void 0 : _b.includes(text))) {
            return childElement;
        }
    }
    // If still not found, return null
    return null;
}
/**
 * Tries to get the button with "Actualizar" text or else null
 */
function getBtnActualizar() {
    const btn = document.getElementById(idButtonActualizarCodigos);
    if (btn) {
        console.log("found submit button");
        return btn;
    }
    const text = "Actualizar";
    const actualizarElement = getBtnThatContainsText(text);
    // If still not found, return null
    if (!actualizarElement) {
        throw new Error("Actualizar element not found.");
    }
    return actualizarElement;
}
function logError(err) {
    let errMsg = typeof err.toString == "function" ? err.toString() : "";
    if (err.stack) {
        errMsg += err.stack;
    }
    console.error(errMsg);
}
async function mainRenew() {
    try {
        const submit = getBtnActualizar();
        if (!submit) {
            console.error('submit button not found');
            return;
        }
        if (submit instanceof HTMLElement) {
            submit.click();
        }
        else {
            throw new Error('error submit is not HTMLElement');
        }
        await (0, utils_1.waitForTimeout)(getRandBetween(4000, 6000));
    }
    catch (err) {
        console.error(err.stack);
    }
}
exports.mainRenew = mainRenew;
