"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const renew_1 = require("./renew");
const utils_1 = require("./utils");
//------------- CONSTANTS -----------------
const botName = 'referallToparrainBot';
//-------------END CONSTANTS --------------
function saveLRToLocalStorage() {
    const now = new Date();
    const executionTime = now.getTime().toString();
    localStorage.setItem("lastExecutionTime", executionTime);
    const executionTimes = (0, utils_1.getArrayFromLocalStorage)('lastExecutionTimes');
    const datetime = (0, utils_1.parseDateTimeFromTimestamp)(executionTime);
    executionTimes.unshift(datetime.toISO());
    console.log(`execution times length: ${executionTimes.length}`);
    localStorage.setItem("lastExecutionTimes", executionTimes.join(','));
}
async function renewWrapper() {
    console.log('renewing');
    // We risk saving it before renewing, so we may get false positives. At some point we can implement a more robust solution with success checking and then saving LR
    saveLRToLocalStorage();
    await (0, renew_1.mainRenew)();
}
async function cron() {
    const lastExecutionTime = localStorage.getItem("lastExecutionTime");
    if (!lastExecutionTime) {
        return await renewWrapper();
    }
    if ((0, utils_1.isLastExecutionTimeOld)(lastExecutionTime)) {
        return await renewWrapper();
    }
}
function reload() {
    window.location.reload();
}
void (async () => {
    setInterval(cron, 1000);
    setInterval(reload, 30 * 60 * 1000);
})();
