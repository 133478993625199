"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDateTimeFromTimestamp = exports.getArrayFromLocalStorage = exports.isLastExecutionTimeOld = exports.waitForTimeout = void 0;
const luxon_1 = require("luxon");
async function waitForTimeout(milliseconds) {
    await new Promise((r) => setTimeout(r, milliseconds));
}
exports.waitForTimeout = waitForTimeout;
function isLastExecutionTimeOld(lastExecutionTime) {
    const now = new Date();
    if (typeof lastExecutionTime == 'string') {
        lastExecutionTime = new Date(Number(lastExecutionTime));
    }
    const diffInMs = now.getTime() - lastExecutionTime.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60); // convert milliseconds to hours
    return diffInHours >= 1;
}
exports.isLastExecutionTimeOld = isLastExecutionTimeOld;
function getArrayFromLocalStorage(key) {
    const storedValue = localStorage.getItem(key);
    if (!storedValue) {
        return [];
    }
    try {
        const parsedValue = storedValue.split(',');
        if (Array.isArray(parsedValue)) {
            return parsedValue;
        }
    }
    catch (error) {
        console.error("Failed to parse stored value:", error);
    }
    return [];
}
exports.getArrayFromLocalStorage = getArrayFromLocalStorage;
function parseDateTimeFromTimestamp(timestamp) {
    timestamp = Number(timestamp);
    return luxon_1.DateTime.fromMillis(timestamp);
}
exports.parseDateTimeFromTimestamp = parseDateTimeFromTimestamp;
